@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

body {
  background-color: #ecf0f1;
  color: #2c3e50;
}

main {
  &.container {
    max-width: 600px;
    margin: 0 auto;
    min-height: 79.3vh;
    .welcome-message {
      text-align: center;
      margin-top: 50px;
      h1 {
        font-size: 2.5rem;
        font-weight: 600;
      }
      h2 {
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
    .sign-up-form {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      input {
        outline: none;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #bdc3c7;
      }
      .sign-up-btn {
        margin-top: 20px;
        padding: 20px;
        color: #ecf0f1;
        font-weight: 600;
        font-size: 1.1rem;
        background-color: #2980b9;
        border: 1px solid #bdc3c7;
        &:hover {
          background-color: #256c9c;
          cursor: pointer;
        }
      }
    }
  }
}

table {
  tbody {
    tr {
      .change {
        background-color: #27ae60;
        color: #ecf0f1;
      }
      .change-down {
        background-color: #c0392b;
        color: #ecf0f1;
      }
      .exchange-btn {
        background-color: #2980b9;
        color: #ecf0f1;
        border: 1px solid #bdc3c7;
        padding: 10px;
        border-radius: 5px;
        &:hover {
          background-color: #256c9c;
          cursor: pointer;
        }
      }
      td {
        padding: 10px;
        border: 1px solid #bdc3c7;
        text-align: center;
      }
    }
  }
}

.wallet-container {
  max-width: 980px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 100px;
  min-height: 79.9vh;
}

.header {
  background-color: #2c3e50;
  color: #ecf0f1;
  .container {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    max-width: 1024px;
    margin: 0 auto;
    .logo img {
      width: 140px;
    }
    .logo a {
      color: #ecf0f1;
    }
    .login-btn {
      padding: 10px;
      color: #ecf0f1;
      font-weight: 500;
      font-size: 1.1rem;
      background-color: #2980b9;
      border: none;
      border-radius: 5px;
      &:hover {
        background-color: #256c9c;
        cursor: pointer;
      }
    }
  }
}

.header-logged {
  background-color: #2c3e50;
  color: #ecf0f1;
  position: fixed;
  width: 100%;
  z-index: 999;
  .container {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    max-width: 1024px;
    margin: 0 auto;
    .menu-links {
      display: flex;
      align-items: center;
      a {
        color: #bdc3c7;
        margin-right: 20px;
        font-weight: 500;
        font-size: 1.1rem;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .logo { 
      img {
      width: 140px;
      }
      a {
        color: #ecf0f1;
      }
    }
    .user {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      .logout-link a {
        color: #bdc3c7;
        float: right;
        font-size: 0.8rem;
        &:hover {
        text-decoration: none;     
        }
      }
    }
  }
}

.footer {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px;
  text-align: center;
}